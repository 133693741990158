import {DeletableItem, FormControl, Recipe} from "../recipe/RecipeModel";
import MenuFormController from "./MenuFormController";

export interface Dish {
    recipe: Recipe
    servings: number
}

export interface Menu {
    id?: string
    name: string
    dishes: Dish[]
}

export interface RecipeChoice {
    label: string,
    recipe: Recipe
}

export interface MenuForm {
    name: FormControl<string>
    dishes: DeletableItem<Dish>[]
    recipes: RecipeChoice[]
    newRecipe: FormControl<Recipe>
    newRecipeServing: FormControl<number>
}

interface Props {
    menu?: Menu | undefined
    recipes: Recipe[],
    onSubmit: (menu: Menu) => void
}

const MenuModel = ({menu, recipes, onSubmit}: Props) => {
    return (<MenuFormController
        menu={menu ?? {
            name: '',
            dishes: []
        }}
        recipes={recipes}
        onSubmit={onSubmit} />);
}

export default MenuModel;