import {Recipe} from "../recipe/RecipeModel";
import {Dish, Menu, MenuForm} from "./MenuModel";
import MenuFormView from "./MenuFormView";
import {FormEvent, useState} from "react";

interface Props {
    menu: Menu
    recipes: Recipe[]
    onSubmit: (menu:Menu) => void
}

const MenuFormController = ({menu, recipes, onSubmit}: Props) => {

    const saveMenu = (e:FormEvent<HTMLFormElement>) => {
        console.log('save the menu', _menu);
        e.preventDefault();
        onSubmit(_menu)
    }

    const [_menu, setMenu] = useState<Menu>(menu);
    const [dishServings, setDishServings] = useState<number>(2);
    const [newRecipe, setNewRecipe] = useState<Recipe>(recipes[0]);

    const setName = (name: string) => {
        setMenu(
            {
                ..._menu,
                name: name
            }
        )
    }

    const removeDish = (index: number) => () => {
        const dishes = [..._menu.dishes];
        dishes.splice(index, 1);

        setMenu({
            ..._menu,
            dishes
        })
    }

    const addDish = () => {
        const newDish: Dish = {
            servings: dishServings,
            recipe: newRecipe
        }
        _menu.dishes.push(newDish)
        setMenu({
            ..._menu,
        })
    }

    const menuForm: MenuForm = {
        name: {
            value: _menu.name,
            set: setName
        },
        dishes: _menu.dishes
            .map((dish, index) => {
                return {
                    value: dish,
                    remove: removeDish(index)
                }
            }),
        recipes: recipes.map( recipe => {
            return {
                label: recipe.name,
                recipe: recipe
            }
        }),
        newRecipe: {
            value: newRecipe,
            set: setNewRecipe
        },
        newRecipeServing: {
            value: (dishServings),
            set: setDishServings
        }
    }




    return (<MenuFormView form={menuForm} addDish={addDish} onSubmit={saveMenu} />)
}

export default MenuFormController;