import MenuModel, {Menu} from "./MenuModel";
import MenuView from "./MenuView";
import {useState} from "react";
import {Ingredient, Recipe} from "../recipe/RecipeModel";

interface Props {
    recipes: Recipe[]
    menu: Menu
    saveMenu: (menu: Menu) => void
    deleteMenu: () => void
}

export interface ShoppingList {
    [name: string] : Ingredient
}

const buildShoppingList = (menu: Menu): Ingredient[] => {

    const shoppingList:ShoppingList = {}

    menu.dishes.flatMap(dish => {
        return dish.recipe.ingredients
            .map(ingredient => {
                const newQty = (ingredient.qty/dish.recipe.serving)*dish.servings
                return {...ingredient, qty: newQty}
            })
    }).forEach(ingredient => {
        if(shoppingList[ingredient.name]){
            shoppingList[ingredient.name].qty += ingredient.qty;
        } else {
            shoppingList[ingredient.name] = ingredient;
        }
    })

    const finalList = Object.keys(shoppingList).map(key => shoppingList[key]);
    return finalList.sort((ing1, ing2) => {
        if(ing1.name < ing2.name) {return -1}
        if(ing1.name > ing2.name) {return 1}
        return 0;
    })
}

const MenusController = ({menu, recipes, saveMenu, deleteMenu}:Props) => {

    const [edit, setEdit] = useState<boolean>(false);

    const toggleEdit = () => {
        setEdit(!edit);
    }

    const _saveMenu = (menu: Menu) => {
        saveMenu(menu);
        toggleEdit();
    }

    const [open, setOpen] = useState<boolean>(false);
    const toggleOpen = () => {
        setOpen(!open);
    }

    return (<>
            {!edit &&
                <MenuView
                    menu={menu}
                    open={open}
                    toggleOpen={toggleOpen}
                    edit={toggleEdit}
                    deleteMenu={deleteMenu}
                    shoppingList={buildShoppingList(menu)}
                />
            }
            {edit &&
                <MenuModel menu={menu} recipes={recipes} onSubmit={_saveMenu} />
            }
        </>
        )
}

export default MenusController