import {MenuForm} from "./MenuModel";
import {FormEvent} from "react";
import {Autocomplete, Button, Grid, IconButton, TextField, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
    form: MenuForm;
    addDish: () => void;
    onSubmit: (e:FormEvent<HTMLFormElement>) => void;
}

const MenuFormView = ({form, addDish, onSubmit}: Props) => {

    return (
        <form onSubmit={onSubmit} >
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography align={'left'}>
                            Menu Name
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            style={{marginRight: 'auto'}}
                            id="menu-name"
                            variant="standard"
                            value={form.name.value}
                            onChange={(e) => form.name.set(e.target.value)}
                            autoFocus
                        />
                    </Grid>
                    {form.dishes.map((deletableDish, index) => {
                       return(
                           <Grid key={index} item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                        <Typography align={'left'}>
                                            {deletableDish.value.recipe.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {deletableDish.value.servings} servings
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton onClick={(e) => {
                                            deletableDish.remove()
                                        }} aria-label="delete">
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                           </Grid>
                       )
                    })}
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={5}>
                        <Autocomplete
                            disablePortal
                            fullWidth
                            size={'small'}
                            id="select-recipe"
                            options={form.recipes}
                            renderInput={(params) => <TextField {...params} variant='standard' label="Recipe" size='small' />}
                            value={
                                form.recipes.find((item) => item.recipe === form.newRecipe.value)
                            }
                            onChange={(event, value) => {
                                if(value) {
                                    form.newRecipe.set(value.recipe);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            type={'number'}
                            size='small'
                            variant={'standard'}
                            value={form.newRecipeServing.value}
                            onChange={(e) => form.newRecipeServing.set(parseInt(e.target.value))}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button onClick={() => addDish()}>
                            Add
                        </Button>
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={8} display='flex' alignItems='centre'>
                        <Button type='submit'>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default MenuFormView;