import {Ingredient, Recipe} from "./recipe/RecipeModel";
import {Menu} from "./menu/MenuModel";

export const menuToShoppingList = (menu: Menu):Ingredient[] => {

    const shoppingList: Ingredient[] = []

    menu.dishes.flatMap(dish => {
        return dish.recipe.ingredients
            .map(ingredient => {
                const newQty = (ingredient.qty/dish.recipe.serving)*dish.servings
                return {...ingredient, qty: newQty}
            })
    }).forEach(ingredient => {
        let ingredientForShoppingList = shoppingList.find(existingIngredient =>
            existingIngredient.name === ingredient.name &&
            existingIngredient.unit === ingredient.unit)
        if(ingredientForShoppingList) {
            ingredientForShoppingList.qty += ingredient.qty;
        } else {
            shoppingList.push(ingredient)
        }
    })

    return shoppingList.sort((ing1, ing2) => {
        if(ing1.name < ing2.name) {return -1}
        if(ing1.name > ing2.name) {return 1}
        return 0;
    })
}

export const getIngredientNames = (recipes: Recipe[]) => {
    const setOfIngredientNames = new Set<string>(
        recipes.flatMap(recipe =>
            recipe.ingredients.map(ingredient => ingredient.name))
    )
    return Array.from(setOfIngredientNames.values())
}

export const getUnits = (recipes: Recipe[]) => {
    const setOfUnits = new Set<string>(
        recipes.flatMap(recipe =>
            recipe.ingredients.map(ingredient => ingredient.unit))
    )
    return Array.from(setOfUnits.values())
}