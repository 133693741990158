import {Menu} from "./MenuModel";
import {Grid} from "@mui/material";
import {Recipe} from "../recipe/RecipeModel";
import MenusController from "./MenuController";

interface Props {
    menus: Menu[]
    recipes: Recipe[]
    saveMenu: (index: number) => (menu: Menu) => Promise<void>
    deleteMenu: (index: number) => () => Promise<void>
}

const MenusView = ({menus, recipes, saveMenu, deleteMenu}:Props) => {
    return (
        <>
            {menus
                .sort((m1, m2) => m1.name.localeCompare(m2.name))
                .map((menu, index) => {
                    return (
                        <Grid key={index} item xs={6}>
                            <MenusController menu={menu} recipes={recipes} saveMenu={saveMenu(index)} deleteMenu={deleteMenu(index)} />
                        </Grid>
                    )
                })
            }
        </>
    )
}

export default MenusView