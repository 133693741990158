import {GraphQLResult} from "@aws-amplify/api-graphql/src/types/index";
import { Menu } from './menu/MenuModel'
import {Recipe} from "./recipe/RecipeModel";
import {API, graphqlOperation} from "aws-amplify";
import {
    createMenu as _createMenu,
    createRecipe as _createRecipe, deleteMenu as _deleteMenu, deleteRecipe as _deleteRecipe,
    updateMenu as _updateMenu,
    updateRecipe as _updateRecipe
} from "./graphql/mutations";
import {listMenus as _listMenus, listRecipes as _listRecipes} from "./graphql/queries";

/*

A facade to protect the application from all the details of
data persistence. API functions and types should go no further
than this file which will talk connect to the app using only
its domain types.
 */

// Internal DTO types
interface MenuDto {
    id?: string,
    name: string,
    dishes: DishDto[]
}

interface DishDto {
    servings: number,
    recipeId: string
}

interface RecipeDto {
    id?: string,
    name: string,
    serving: number,
    method: string,
    ingredients: IngredientDto[]
}

interface IngredientDto {
    name: string,
    qty: number,
    unit: string
}

// Recipes

interface RecipeResponse {
    id: string,
    name: string,
    serving?: number | null,
    method?: string | null,
    ingredients?:  Array< {
        name: string,
        qty: number,
        unit: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
}

interface ListRecipesResponse {
    listRecipes: {
        items: RecipeResponse[]
    }
}

interface CreateRecipeResponse {createRecipe: RecipeResponse }

interface UpdateRecipeGQLResponse {updateRecipe: RecipeResponse }

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return !(value === null || value === undefined);

}

const hasData = <T>(result: GraphQLResult<any>):T => {
    const typedResponse = result as GraphQLResult<T>;

    if(!typedResponse.data) {
        console.log(typedResponse.errors);
        throw new Error('No data block returned from back end')
    }

    return typedResponse.data
}

const convertRecipeResponseToRecipe = (recipeResponse: RecipeResponse): RecipeDto => {
    return {
        id: recipeResponse.id,
        name: recipeResponse.name,
        serving: recipeResponse.serving ?? 0,
        method: recipeResponse.method ?? '',
        ingredients: recipeResponse.ingredients ?
            recipeResponse.ingredients
                .filter(notEmpty)
                .map(ingredient => {
                    return {
                        name: ingredient.name,
                        qty: ingredient.qty,
                        unit: ingredient.unit
                    }
                }) : []
    }
}

export const createRecipe = async (recipe: Recipe): Promise<RecipeDto> => {
    if(recipe.id) {
        throw new Error('Attempting to create a new recipe from one with an id');
    }
    const response = await API.graphql(graphqlOperation(_createRecipe, {input: recipe}));
    const typedResponse = hasData<CreateRecipeResponse>(response)
    return convertRecipeResponseToRecipe(typedResponse.createRecipe);
}

export const updateRecipe = async (recipe: Recipe): Promise<Recipe> => {
    const response = await API.graphql(graphqlOperation(_updateRecipe, {input: recipe}));
    const typedResponse = hasData<UpdateRecipeGQLResponse>(response)
    return convertRecipeResponseToRecipe(typedResponse.updateRecipe);
}

export const listRecipes = async (): Promise<Recipe[]> => {
    const response = await API.graphql(graphqlOperation((_listRecipes)));
    const typedResponse = hasData<ListRecipesResponse>(response);
    return typedResponse.listRecipes.items.map(recipeResponse => {
        return convertRecipeResponseToRecipe(recipeResponse)
    })
}

export const deleteRecipe = async (recipe:Recipe) => {
    if(!recipe.id) {
        throw new Error('attempting to delete a recipe without an id')
    }
    await API.graphql(graphqlOperation(_deleteRecipe, {input: {id: recipe.id}}));
}

//Menus
interface ListMenuResponse {
    listMenus: {
        items: MenuResponse[]
    }
}

interface CreateMenuResponse {createMenu: MenuResponse }

interface UpdateMenuResponse {updateMenu: MenuResponse }

interface MenuResponse {
    id: string,
    name: string,
    dishes?:  Array< {
        recipeId: string,
        servings: number,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
}

const convertMenuResponseToMenu = (menuResponse: MenuResponse, recipesMap: Map<string, RecipeDto> ): Menu => {
    return {
        id: menuResponse.id,
        name: menuResponse.name,
        dishes: menuResponse.dishes ?
            menuResponse.dishes
                .filter(notEmpty)
                .filter(dish => recipesMap.has(dish.recipeId))
                .map(dish => {
                    const  recipe = recipesMap.get(dish.recipeId);
                    if(!recipe) { // filter above should prevent this, just for typesafety
                        throw new Error('recipe not found by id when constructing menu')
                    }
                    return {
                        recipe: recipe,
                        servings: dish.servings
                    }
                }) : []
    }
}

export const updateMenu = async (menu: Menu, recipeMap: Map<string, RecipeDto>): Promise<Menu> => {
    if(!menu.id) {
        throw new Error('Attempting to update a menu without an id')
    }
    const menuDto = convertDomainMenuToMenuDto(menu);
    const response = await API.graphql(graphqlOperation(_updateMenu, {input: menuDto}));
    const typedResponse = hasData<UpdateMenuResponse>(response)
    return convertMenuResponseToMenu(typedResponse.updateMenu, recipeMap);
}

export const createMenu = async (menu: Menu, recipeMap: Map<string, RecipeDto>): Promise<Menu> => {
    if(menu.id) {
        throw new Error('Attempting to create a menu with an id')
    }
    const menuDto = convertDomainMenuToMenuDto(menu);
    const response = await API.graphql(graphqlOperation(_createMenu, {input: menuDto}));
    const typedResponse = hasData<CreateMenuResponse>(response)
    return convertMenuResponseToMenu(typedResponse.createMenu, recipeMap);
}

export const listMenus = async (recipeMap: Map<string, RecipeDto>): Promise<Menu[]> => {
    const response = await API.graphql(graphqlOperation(_listMenus));
    const typedResponse = hasData<ListMenuResponse>(response);
    return typedResponse.listMenus.items.map(menuResponse => {
        return convertMenuResponseToMenu(menuResponse, recipeMap)
    })
}

export const deleteMenu = async (menu: Menu) => {
    if(!menu.id) {
        throw new Error('attempting to delete a menu without an id')
    }
    await API.graphql(graphqlOperation(_deleteMenu, {input: {id: menu.id}}));
}

export const convertDomainMenuToMenuDto = (menu: Menu):MenuDto => {
    return {
        id: menu.id,
        name: menu.name,
        dishes: menu.dishes ?
            menu.dishes
            .filter(dish => notEmpty(dish.recipe.id))
            .map( dish => {
                if(!dish.recipe.id) {
                    throw Error('Attempting to convert a menu with an unsaved recipe')
                }
            return {
                servings: dish.servings,
                recipeId: dish.recipe.id
            }
        }) : []
    }
}