import RecipeFormView from "./RecipeFormView";
import {FormEvent, useState} from "react";
import {Recipe, RecipeForm} from "./RecipeModel";

interface Props {
    recipe: Recipe;
    ingredientNames: string[];
    units: string[]
    onSubmit: (recipe:Recipe)=>void
}

const RecipeFormController = (props:Props) => {

    const initNewRecipe = (): Recipe => {
        return {
            name: '',
            serving: 2,
            ingredients: [],
            method: ''
        }
    }

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            console.log('saving recipe', recipe);
            props.onSubmit(recipe);
            setRecipeModel(initNewRecipe())
        } catch (err) {
            console.log(err)
        }
    }

    const [recipe, setRecipeModel] = useState<Recipe>(props.recipe);

    const setName = (name:string) => {
        setRecipeModel({
            ...recipe,
            name
        })
    }

    const setServings = (servings: number) => {
        setRecipeModel({
            ...recipe,
            serving: servings
        })
    }

    const setMethod = (method: string) => {
        setRecipeModel({
            ...recipe,
            method
        })
    }

    const setIngredientName = (index: number) => (name: string) => {
        console.log(name);
        let ingredients = [...recipe.ingredients];
        ingredients[index].name = name;
        setRecipeModel({
            ...recipe,
            ingredients
        });
    }

    const setIngredientQty = (index: number) => (qty: number) => {
        let ingredients = [...recipe.ingredients];
        ingredients[index].qty = qty;
        setRecipeModel({
            ...recipe,
            ingredients
        });
    }

    const setIngredientUnit = (index: number) => (unit: string) => {
        let ingredients = [...recipe.ingredients];
        ingredients[index].unit = unit;
        setRecipeModel({
            ...recipe,
            ingredients
        });
    }

    const addIngredient = () => {
        if(recipe.ingredients.length > 0 &&
            recipe.ingredients[recipe.ingredients.length-1].name === "") {
            //Can't add an ingredient unless you have named the last one.
            return;
        }
        setRecipeModel({
            ...recipe,
            ingredients: [...recipe.ingredients, {
                name: '',
                qty: 0,
                unit: '',
            }]
        });
    }

    const deleteIngredient = (index:number) => {
        const newIngredient = [...recipe.ingredients]
        newIngredient.splice(index, 1);
        setRecipeModel({
            ...recipe,
            ingredients: newIngredient
        });
    }

    const recipeForm: RecipeForm = {
        name: {
            value: recipe.name,
            set: setName
        },
        servings: {
          value: recipe.serving,
          set: setServings
        },
        ingredients: [],
        method: {
            value: recipe.method,
            set: setMethod
        }
    }

    recipeForm.ingredients = recipe.ingredients
        .map((ingredient, index) => {
            return {
            name: {
                value: ingredient.name,
                set: setIngredientName(index)
            },
            qty: {
                value: ingredient.qty,
                set: setIngredientQty(index)
            },
            unit: {
                value: ingredient.unit,
                set: setIngredientUnit(index)
            }
        }});

    return <RecipeFormView
        form={recipeForm}
        addIngredient={addIngredient}
        deleteIngredient={deleteIngredient}
        onSubmit={onSubmit}
        ingredientNames={props.ingredientNames}
        units={props.units}
    />
}

export default RecipeFormController;