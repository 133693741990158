import {Recipe} from "./RecipeModel";
import RecipeView from "./RecipeView";
import {Grid} from "@mui/material";

interface Props {
    recipes:Recipe[]
    deleteRecipe: (index:number) => void
    saveRecipe: (index:number, recipe:Recipe) => void
    ingredientNames: string[]
    units: string[]
}

const RecipesView = ({recipes, deleteRecipe, saveRecipe, ingredientNames, units}:Props) => {

    const _deleteRecipe = (index: number) => () => {deleteRecipe(index)}
    const _saveRecipe = (index: number) => (recipe:Recipe) => {saveRecipe(index, recipe)}

    return (
        <Grid container spacing={2}>
            {
                recipes
                    .sort((r1, r2) => r1.name.localeCompare(r2.name))
                    .map((recipe, i) => {
                    return (
                        <Grid key={i} item xs={12} md={6} lg={4}>
                            <RecipeView
                                recipe={recipe}
                                deleteRecipe={_deleteRecipe(i)}
                                saveRecipe={_saveRecipe(i)}
                                ingredientNames={ingredientNames}
                                units={units}
                                />
                        </Grid>
                    )
                })
            }
        </Grid>
        )
}

export default RecipesView;