import RecipeModel, {Recipe} from "./RecipeModel";
import {ButtonGroup, Grid, IconButton, List, ListItem, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useState} from "react";
import {ChevronRight, KeyboardArrowDown} from "@mui/icons-material";
import '../App.css'

interface Props {
    recipe:Recipe
    deleteRecipe: () => void
    saveRecipe: (recipe:Recipe) => void
    ingredientNames: string[]
    units: string[]
}

const RecipeView = ({recipe, deleteRecipe, ingredientNames, units, saveRecipe}:Props) => {

    const [edit, setEdit] = useState<boolean>(false);

    const toggleEdit = () => {
        setEdit(!edit);
    }

    const [ingredientDisplay, setIngredientDisplay] = useState<boolean>(false);

    const toggleIngredients = () => {
        setIngredientDisplay(!ingredientDisplay);
    }

    const save = (recipe:Recipe) => {
        console.log('should be updating the damn recipe', recipe)
        saveRecipe(recipe)
        toggleEdit();
    }

    return (
        <div className={'dropdown-panel'}>
        {edit &&
            <div style={{paddingLeft: '8px'}}>
                <RecipeModel onSubmit={save} recipe={recipe} ingredientsNames={ingredientNames} units={units}/>
            </div>
        }
        {!edit &&
       <>
           <Grid container spacing={2}>
               <Grid item xs={1}>
                   <IconButton onClick={(e) => {
                       toggleIngredients()
                   }}
                               aria-label="open">
                       {!ingredientDisplay && <ChevronRight/> }
                       {ingredientDisplay && <KeyboardArrowDown/> }
                   </IconButton>
               </Grid>
               <Grid item xs={9}>
                   <Typography className={'dropdown-title'} align={'left'} fontWeight={'bold'}>{recipe.name}</Typography>
               </Grid>
               <Grid item xs={2}>
                   <ButtonGroup>
                       <IconButton size={'small'} onClick={(e) => {
                           deleteRecipe()
                       }
                       } aria-label="delete">
                           <DeleteIcon fontSize={'small'}/>
                       </IconButton>

                       <IconButton size={'small'} onClick={(e) => {
                           toggleEdit()
                       }}
                                   aria-label="edit">
                           <EditIcon fontSize={'small'}/>
                       </IconButton>
                   </ButtonGroup>
               </Grid>
               {ingredientDisplay &&
               <>
                   <Grid item xs={1}>
                   </Grid>
                   <Grid item xs={11}>
                       <p className={'serving'}>Servings: {recipe.serving}</p>
                   </Grid>
                   {recipe.method && recipe.method.length > 0 &&
                       <>
                           <Grid item xs={1}>
                           </Grid>
                           <Grid item xs={11}>
                               <pre className={'method'}><b>Method:</b> {recipe.method}</pre>
                           </Grid>
                       </>
                   }
                   <Grid item xs={1}>
                   </Grid>
                   <Grid item xs={7}>
                       <p className={'ingredient-header'}>Ingredient</p>
                   </Grid>
                   <Grid item xs={2}>
                       <p className={'ingredient-header'}>Qty</p>
                   </Grid>
                   <Grid item xs={2}>
                       <p className={'ingredient-header'}>Unit</p>
                   </Grid>
                   <Grid item xs={1}>
                   </Grid>
                   <Grid style={{paddingTop: '0px'}} item xs={11}>
                       <List>
                           {recipe.ingredients.map((ingredient, j) => {
                               return (
                                   <ListItem key={j} disablePadding style={{marginBottom: '5px'}}>
                                       <Grid container spacing={2}>
                                           <Grid item xs={8}>
                                               {ingredient.name}
                                           </Grid>
                                           <Grid item xs={2}>
                                               {ingredient.qty}
                                           </Grid>
                                           <Grid item xs={2}>
                                               {ingredient.unit}
                                           </Grid>
                                       </Grid>
                                   </ListItem>
                               )
                           })}
                       </List>
                   </Grid>
               </>
               }
           </Grid>
       </>
    }
        </div>
    );
}

export default RecipeView;