import React, {useEffect, useState} from 'react';
import Amplify from 'aws-amplify'
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import awsExports from "./aws-exports";
import {BrowserRouter as Router, Link, Route, Routes} from "react-router-dom";
import {AppBar, Box, Button, Grid, Toolbar, Typography} from "@mui/material";

import RecipesView from "./recipe/RecipesView";
import RecipeModel, {AppData, Recipe} from "./recipe/RecipeModel";
import MenusView from "./menu/MenusView";
import MenuModel, {Menu} from "./menu/MenuModel";

import {
  createMenu,
  createRecipe,
  deleteMenu as _deleteMenu,
  deleteRecipe as _deleteRecipe,
  listMenus,
  listRecipes,
  updateMenu,
  updateRecipe
} from "./ApiFacade";
import {getIngredientNames, getUnits} from "./utils";

Amplify.configure(awsExports);

const mapRecipes = (recipes:Recipe[]) : Map<string, Recipe> => {
  const recipeMap = new Map<string, Recipe>();
  recipes.forEach(recipe => {
    if(recipe.id){
      recipeMap.set(recipe.id, recipe);
    }
  })
  return recipeMap;
}

const loadOrInitialiseAppData = async (): Promise<AppData> => {
  const recipes = await listRecipes();
  const recipeMap = mapRecipes(recipes);
  const menus = await listMenus(recipeMap);
  return {recipes: recipes, menus: menus}
}

const saveRecipe = async (recipe: Recipe) => {
  if(recipe.id){
    return updateRecipe(recipe);
  } else {
    return createRecipe(recipe);
  }
}

const saveMenu = async (menu: Menu, recipes: Recipe[]) => {
  if(menu.id){
    return updateMenu(menu, mapRecipes(recipes));
  } else {
    return createMenu(menu, mapRecipes(recipes));
  }
}

const App = () => {

  const [recipes, setRecipes] = useState<Recipe[]>([])
  const [menus, setMenus] = useState<Menu[]>([])
  const [ingredientNames, setIngredientNames] = useState<string[]>([]);
  const [units, setUnits] = useState<string[]>([]);

  useEffect(() => {
    const appData = loadOrInitialiseAppData();
    appData.then(data => {
      setRecipes(data.recipes);
      setMenus(data.menus);
      setUnits(getUnits(data.recipes) ?? []);
      setIngredientNames(getIngredientNames(data.recipes));
    })
  }, [])

  const saveNewRecipe = async (recipe:Recipe) => {
    try {
      const savedRecipe = await saveRecipe(recipe);
      const newRecipes = [
        ...recipes,
        savedRecipe
      ];
      setRecipes(newRecipes);
      setIngredientNames(getIngredientNames(recipes));
      setUnits(getUnits(recipes));
    } catch (e) {
      console.log('there was an error saving the recipe');
    }
  }

  const updateRecipe = async (index:number, recipe:Recipe) => {
    try {
      const savedRecipe = await saveRecipe(recipe);
      const newRecipes = [...recipes];
      newRecipes.splice(index, 1, savedRecipe)
      setRecipes(newRecipes);
      setIngredientNames(getIngredientNames(recipes));
      setUnits(getUnits(recipes));
    } catch (e) {
      console.log('error updating recipe');
    }
  }

  const deleteRecipeFromMenus = async (recipeToDelete: Recipe) => {
    for(let i = 0; i < menus.length; i++) {
      const menu = menus[i];
      const originalNumberOfRecipes = menu.dishes.length;
      const filteredDishes = menu.dishes.filter( dish => dish.recipe.id !== recipeToDelete.id);
      if(filteredDishes.length !== originalNumberOfRecipes) {
        menu.dishes = filteredDishes;
        await updateMenu(i)(menu)
      }
    }
  }

  const deleteRecipe = async (index:number) => {
    try {
      const recipeToDelete = recipes[index];
      await deleteRecipeFromMenus(recipeToDelete);
      await _deleteRecipe(recipeToDelete);
      recipes.splice(index, 1)
      const newRecipes = [
        ...(recipes),
      ];
      setRecipes(newRecipes);
      setIngredientNames(getIngredientNames(recipes));
      setUnits(getUnits(recipes));
    } catch (e) {
      console.log('error deleting recipe');
    }
  }

  const saveNewMenu = async (menu: Menu) => {
    try {
      const savedMenu = await saveMenu(menu, recipes);
      const newMenus = [
        ...menus,
        savedMenu
      ]
      setMenus(newMenus)
    } catch (e) {
      console.log('error saving menu')
    }
  }

  const updateMenu = (index:number) => async (menu:Menu) => {
    try {
      const savedMenu = await saveMenu(menu, recipes);
      const newMenus = [...menus];
      newMenus.splice(index, 1, savedMenu);
      setMenus(newMenus);
    } catch (e) {
      console.log('there was an error updated a menu')
    }
  }

  const deleteMenu = (index:number) => async () => {
    try {
      const menuToDelete = menus[index];
      await _deleteMenu(menuToDelete);
      const newMenus = [...menus];
      newMenus.splice(index,1);
      setMenus(newMenus);
    } catch (e) {
      console.log('error deleting menu')
    }
  }

  return (
      <Authenticator>
        {({ signOut, user }) => (
            <Router>
              <div className="App">
                <Box sx={{ flexGrow: 1 }} className={'header'}>
                  <AppBar position="static">
                    <Toolbar>
                      <RestaurantMenuIcon style={{marginRight: '15px'}} fontSize={'large'} />
                      <Typography align='left' variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <Link to="/">View Recipes</Link> {" | "}
                        <Link to="addRecipe">Add Recipe</Link> {" | "}
                        <Link to="viewMenu">Veiw Menu</Link> {" | "}
                        <Link to="addMenu">Add Menu</Link> {" | "}
                      </Typography>
                      <AccountCircleIcon className={'accountIcon'} />
                      <Typography align={'right'}>
                        {user.username} | <Button size={'small'} variant={'contained'} onClick={() => {signOut()}} >Signout</Button>
                      </Typography>
                    </Toolbar>
                  </AppBar>
                </Box>

                <Grid className={'App-body'} container spacing={2}>
                  <Routes>
                    <Route path="/" element={
                      <RecipesView recipes={recipes} deleteRecipe={deleteRecipe} saveRecipe={updateRecipe} ingredientNames={ingredientNames} units={units} />
                    } />
                    <Route path="addRecipe" element={<RecipeModel ingredientsNames={ingredientNames} units={units} onSubmit={saveNewRecipe} />} />
                    <Route path="viewMenu" element={<MenusView menus={menus} recipes={recipes} saveMenu={updateMenu} deleteMenu={deleteMenu} />} />
                    <Route path="addMenu" element={<MenuModel recipes={recipes} onSubmit={saveNewMenu} />} />
                  </Routes>
                </Grid>
              </div>
            </Router>
        )}
      </Authenticator>
  )
}

export default App