import RecipeFormController from "./RecipeFormController";
import {Menu} from "../menu/MenuModel";

export interface AppData {
    recipes: Recipe[],
    menus: Menu[]
}

export interface Ingredient {
    name: string;
    qty: number;
    unit: string;
}

export interface Recipe {
    id?: string;
    name: string;
    serving: number;
    ingredients: Ingredient[]
    method: string
}

export interface FormControl<T> {
    value: T;
    set: (value: T) => void;
}

export interface DeletableItem<T> {
    value: T;
    remove: () => void
}

export interface IngredientForm {
    name: FormControl<string>;
    qty: FormControl<number>;
    unit: FormControl<string>;
}

export interface RecipeForm {
    name: FormControl<string>;
    servings: FormControl<number>;
    ingredients: IngredientForm[];
    method: FormControl<string>;
}

interface Props {
    recipe?: Recipe | undefined;
    ingredientsNames: string[];
    units: string[];
    onSubmit: (recipe: Recipe) => void;
}

const RecipeModel = ({recipe, ingredientsNames, units, onSubmit}: Props) => {

    const saveRecipe = (recipe:Recipe) => {
        onSubmit(recipe);
    }

    return (<RecipeFormController
        recipe={recipe ?? {
            name: '',
            serving: 2,
            ingredients: [],
            method:''
        }}
        ingredientNames={ingredientsNames}
        units={units}
        onSubmit={saveRecipe} />);
}

export default  RecipeModel;